@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-tabs {
  margin-top: rem-calc($component-padding-base);
  padding-bottom: 0;

  fil-tab {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }

    .fil-text {
      .grid-container {
        padding-left: 0;
        padding-right: 0;

        .fil-text__body {
          .cell {
            h3,
            h4,
            h5,
            h6 {
              margin-top: rem-calc(36);
            }
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:156";