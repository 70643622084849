@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

/* make fil-button span visible, otherwise hided by fil-cta GDS1 style. */
.fil-button {
  &--label {
    display: block !important;
  }
}


/* when a link in button group, it will display incorrectly.
will check with team to handle pdf link issue */
fil-button-group {
  a.fil-button {
    grid-row: 1;
    align-self: baseline;

    @include breakpoint(medium down) {
      width: 100%;
      margin-bottom: rem-calc(16);
    }
  }
}


/* when some grey sections in GDS1 and GDS3 are combined,
the margin-top of the second section should be removed. */
section.bg-grey-25,
section.bg-grey-50 {
  + section.bg-grey-25,
  + section.bg-grey-50 {
    margin-top: rem-calc(-$component-padding-base) !important;
    padding-top: 0 !important;
  }
}

;@import "sass-embedded-legacy-load-done:139";