@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-api-data-renderer {
  margin-bottom: rem-calc(36);

  .table_heading {
    padding-top: rem-calc(20);
  }
}

.snapshot-component-wrapper {
  padding: rem-calc(40 32);
  font-weight: bold;

  .horizontal-line {
    hr {
      margin: rem-calc(20) auto;
    }
  }

  .grey {
    background-color: $grey-25 !important;
  }

  .mb-8 {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(16) !important;
  }

  .bold_text {
    font-weight: 500 !important;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .p-main {
    font-size: rem-calc(17.6) !important;
    font-weight: bold !important;
    padding-left: rem-calc(0) !important;

    @include breakpoint(small) {
      padding-left: rem-calc(16);
    }
  }

  .p-sub {
    padding-top: rem-calc(43.2) !important;

    @include breakpoint(small) {
      padding-top: rem-calc(0) !important;
    }
  }

  .headings {
    padding-top: rem-calc(24) !important;
    padding-left: rem-calc(24);
    font-weight: bolder;
    text-align: left !important;

    @include breakpoint(small) {
      padding-top: rem-calc(0);
    }
  }

  .sub-headings {
    padding-left: rem-calc(24);
    font-weight: bolder;
    padding-top: rem-calc(64);
    text-align: left !important;

    @include breakpoint(small) {
      padding-top: rem-calc(0);
    }
  }

  .note_content {
    padding: rem-calc(0 24 24 ) !important;
    font-weight: bolder;

  }

  .float_content_upper {
    padding-left: 20%;
    font-weight: bolder;
    padding-top: rem-calc(77.6);
    margin-left: rem-calc(32);
    text-align: left !important;

    @include breakpoint(small only) {
      padding-top: rem-calc(0);
      margin-left: rem-calc(0);
      padding-left: rem-calc(24);
    }

    @include breakpoint(medium only) {
      margin-left: rem-calc(0);
      padding-top: rem-calc(16);
      padding-left: rem-calc(24);
    }

  }

  .float_content_lower {
    padding-left: 20%;
    font-weight: bolder;
    text-align: left !important;
    margin-left: rem-calc(32);

    @include breakpoint(small only) {
      padding-top: rem-calc(0);
      margin-left: rem-calc(0);
      padding-left: rem-calc(24);
    }

    @include breakpoint(medium only) {
      margin-left: rem-calc(0);
      padding-top: rem-calc(16);
      padding-left: rem-calc(24);
    }
  }

  .text_red {
    color: $red-400 !important;

    .text-error-red {
      padding: rem-calc(32 0 0 32);
      color: $red-400 !important;

      @include breakpoint(small only) {
        padding-top: rem-calc(0);
      }
    }
  }

  .text_green {
    color: $green-700 !important;

    .text-error-green {
      padding: rem-calc(32 0 0 32);
      color: $green-700 !important;

      @include breakpoint(small only) {
        padding-top: rem-calc(0);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:147";