//Enforcing the base font size for rems, !important to overwrite tools
@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

html {
  font-size: rem-calc(16) !important;
}

h2 {
  @include breakpoint(medium down) {
    font-size: rem-calc(34);
  }
}

a {
  &:hover {
    color: $grey-900;
    text-decoration: underline !important;
  }
}

@include breakpoint(small only) {
  ul,
  ol {
    margin-left: rem-calc(24);

    li {
      &::before {
        left: rem-calc(-24);
      }
    }

    ul,
    ol {
      margin-left: rem-calc(25);
      margin-top: rem-calc(12);
    }
  }
}

;@import "sass-embedded-legacy-load-done:138";