@use '@fil-global/gds-core/scss/utils/bootstrap' as *;


.fil-showhide {
  margin-bottom: 0;
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  &__content {
    &--image {
      margin-top: rem-calc(24);
    }
  }

  .fil-text-container {
    .grid-container {
      padding: 0 !important;

      .cell {
        width: 100% !important;
      }
    }
  }
}


;@import "sass-embedded-legacy-load-done:155";