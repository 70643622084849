@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
@use '@fil-global/gds-components/src/scss/components/fil-list' as *;

.fil-accordion {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);
  margin-bottom: 0 !important;

  @include breakpoint(medium) {
    margin-bottom: rem-calc(40);
  }

  .fil-title-paragraph {
    ul {
      margin-bottom: rem-calc(40);

      @include fil-list;
    }

    ol {
      margin-bottom: rem-calc(40);

      @include fil-list;
    }

    a {
      text-decoration-line: underline;
    }
  }

  .fil-video {
    .kv-viewer {
      .kv-thumbnail {
        width: 100% !important;
        height: inherit !important;
      }

      div {
        width: 100% !important;
        height: inherit !important;
      }

      .kv-packshot-button {
        width: 100% !important;
        height: inherit !important;
      }

      .kv-packshot-button + .kv-play-icon img,
      .kv-packshot-button .kv-play-icon img {
        max-width: rem-calc(72);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:148";