@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.reveal-wrapper {

  .fil-text-container {
    .grid-container {
      padding: 0;

      .cell {
        width: 100%;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:152";