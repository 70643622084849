@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

/* component base */
$component-padding-base: 24;
$title-keyline-above-baton: 30;
$title-keyline-below-baton: 40;

/* card components */
$card-padding-bottom-mobile: 24;
$card-grid-padding-bottom-mobile: $component-padding-base - $card-padding-bottom-mobile;
$card-grid-padding-bottom-grey-mobile: $component-padding-base * 2 - $card-padding-bottom-mobile;

// TODO: replace ID selector
#body-container { /* stylelint-disable-line selector-max-specificity */
  overflow-x: hidden;
  min-height: 100vh;
}

.small-centered {
  margin-left: auto;
  margin-right: auto;
}

@include breakpoint(small only) {
  .small-centered-only {
    margin-left: auto;
    margin-right: auto;
  }

  .small-text-center {
    text-align: center;
  }
}

@include breakpoint(medium) {
  .medium-centered {
    margin-left: auto;
    margin-right: auto;
  }
}

@include breakpoint(large) {
  .large-centered {
    margin-left: auto;
    margin-right: auto;
  }
}

// .grid-container {
//   max-width: rem-calc($gcl-global-width) !important
// }

body {
  background: $white;
  //font-size: rem-calc(16) !important;

  &.is-mobile {
    &.is-sub-open {
      position: fixed;
      overflow: hidden;
      width: 100vw;
      height: 100vh;
    }
  }
}

section.fil-iframe,
section.fil-factsheet {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);
}

section.bg-grey-25 {
  padding-top: rem-calc($component-padding-base * 2) !important;
  padding-bottom: rem-calc($component-padding-base * 2) !important;
  margin-top: rem-calc($component-padding-base) !important;
  margin-bottom: rem-calc($component-padding-base) !important;

  + section.bg-grey-25 {
    margin-top: rem-calc(-$component-padding-base) !important;
    padding-top: 0 !important;
  }

  + .fil-global-footer {
    margin-top: rem-calc(-$component-padding-base * 2);
  }
}

.title-keyline {
  position: relative;
  color: $grey-800;
  margin-bottom: rem-calc($title-keyline-below-baton);
  padding-bottom: rem-calc($title-keyline-above-baton);
  font-weight: normal;

  &::after {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-25);
    bottom: 0;
    content: '';
    height: rem-calc(3);
    width: rem-calc(50);
    background-color: $blue-500;
  }

  &.keyline-left {
    &::after {
      left: 0;
      margin-left: 0;
    }
  }

  &.super {
    &::after {
      height: rem-calc(4);
    }
  }

  + p {
    margin-top: rem-calc(-26) !important;
    margin-bottom: rem-calc(56) !important;
  }
}

.grid-x.align-center {
  justify-content: center;
}

.fil-title-paragraph + .fil-cta {
  padding-top: 0;
}

.fil-title-paragraph p:last-child {
  margin-bottom: 0;
}

[class*="fil-"] {
  .gds3-table-wrapper {
    margin-bottom: rem-calc(40);

    .fil-table {
      border-right: none;
      margin-bottom: 0;

      td,
      th {
        border: none;
      }
    }
  }
}

a.external {
  &::after {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fil-icon" fill="none" stroke="%23016fad" stroke-width="2"><polyline points="23.251 7.498 23.251 0.748 16.501 0.748" /><line x1="23.251" y1=".748" x2="8.251" y2="15.748" /><path d="M11.251,5.248h-9a1.5,1.5,0,0,0-1.5,1.5v15a1.5,1.5,0,0,0,1.5,1.5h15a1.5,1.5,0,0,0,1.5-1.5v-9" /></svg>');
    content: "";
    background-repeat: no-repeat;
    background-size: rem-calc(16 16);
    display: inline-block;
    width: rem-calc(16);
    height: rem-calc(16);
    margin-left: rem-calc(6);
  }
}

@include breakpoint(small only) {
  .grid-container {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  .title-keyline {
    margin-bottom: rem-calc(40);
    padding-bottom: rem-calc(22);
    font-size: rem-calc(33);

    + p {
      margin-top: rem-calc(-18) !important;
      margin-bottom: rem-calc(40) !important;
    }
  }

  .fil-comparison-list-container {
    &.grid-margin-x {
      margin-left: 0;
      margin-right: 0;

      .cell {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .owl-item.active {
      margin-right: 0 !important;
    }
  }
}

.headless {
  .global-section,
  .header-wrapper,
  .fil-global-footer,
  a[headless="hide"] {
    display: none !important;
  }
}

@mixin support-cjk-lang {
  @at-root {
    :lang(ja) &,
    :lang(zh-hans) &,
    :lang(zh-hant) &,
    :lang(ko) & {
      @content;
    }
  }
}

fil-button.full-width {
  width: 100%;
}

//add badge style
.fil-badge {
  margin-left: rem-calc(10);
  border-radius: rem-calc(20);
  font-size: rem-calc(14);
  color: $white;
  display: inline-block !important;
  padding: rem-calc(4 12);

  &.warning {
    background-color: $brand-red;
  }
}

;@import "sass-embedded-legacy-load-done:136";