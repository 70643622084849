@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
//@use '@fil-global/gds-components/scss/pull-out/pull-out';

.fil-pullout {
  .grid-container {
    padding-left: 0;
    padding-right: 0;
  }

  &.banner-grey {
    background: var(--fil-pull-out-banner-grey-bg-color, var(--fil-color-base-grey-500));
  }

  &.banner-blue {
    background: var(--fil-pull-out-banner-bg-color, var(--fil-color-pull-out-banner-background));
  }
}

;@import "sass-embedded-legacy-load-done:154";