@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-cta {
  padding-top: 0;
  padding-bottom: rem-calc($component-padding-base);

  .fil-button--textlink {
    text-decoration: none !important;

    .fil-button--text {
      text-decoration: underline;
    }
  }

  .cell {
    fil-button,
    .fil-button {
      margin: 0 rem-calc(8);
      display: inline-flex;

      @include breakpoint(small only) {
        width: 100%;
        margin: 0 0 rem-calc(20) 0;
      }
    }

    @include breakpoint(small only) {
      width: 100%;
      text-align: center;

      &:last-of-type fil-button {
        margin-bottom: 0;
      }
    }

    &.textlink {
      padding: rem-calc(11 0);
    }
  }

  .align-center {
    justify-content: center;
  }

  & + .fil-cta {
    padding-top: 0;
  }
}

.bg-grey-25 + .fil-cta.bg-grey-25 {
  margin-top: rem-calc(-$component-padding-base) !important;
}

;@import "sass-embedded-legacy-load-done:150";