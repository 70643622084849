@use "@fil-global/gds-core/scss/utils/bootstrap" as *;

.fil-text {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  $root-class: &;

  #{$root-class}__body {
    p:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration-line: underline;
    }

    h1 {
      margin-bottom: rem-calc(16);
      text-align: left;
    }

    h2,
    h3 {
      margin-bottom: rem-calc(12) !important;
      text-align: left;
    }

    h2 {
      font-size: rem-calc(36) !important;
    }

    h4,
    h5,
    h6 {
      margin-bottom: rem-calc(8);
      text-align: left;
    }

    @include breakpoint(medium down) {
      h2 {
        font-size: rem-calc(32) !important;
      }

      h3 {
        margin-bottom: rem-calc(8);
      }

      h4,
      h5,
      h6 {
        margin-bottom: rem-calc(4);
      }

      h6 {
        font-size: rem-calc(20);
      }
    }
  }

  ul,
  ol {
    word-break: break-word;
  }

  &.important-info {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: rem-calc($component-padding-base) !important;
    margin-bottom: rem-calc($component-padding-base);

    #{$root-class}__body > .cell {
      padding-top: rem-calc($component-padding-base);
      padding-bottom: rem-calc($component-padding-base);
      border-top: rem-calc(1) solid $grey-100;
      border-bottom: rem-calc(1) solid $grey-100;
    }

    + .important-info {
      border-top: none;
    }

    @at-root section.bg-grey-25 + & {
      margin-top: rem-calc($component-padding-base * 2) !important;
    }

    + section.bg-grey-25 {
      margin-top: rem-calc($component-padding-base * 2) !important;
    }
  }

  &-body-container {
    margin-bottom: 0;

    &.small {
      div,
      p,
      li {
        font-size: inherit;
      }
    }
  }

  .fil-table-wrapper {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  table {
    display: block;
    width: 100% !important;
    overflow: hidden;

    p {
      margin-bottom: 0;
    }
  }

  .table-scroll {
    width: 100%;
    overflow-x: auto;
    margin-bottom: rem-calc(40);

    table {
      display: table;
      width: 100% !important;
      margin: 0 auto;
      overflow: auto;
    }
  }
}

;@import "sass-embedded-legacy-load-done:157";